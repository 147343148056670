export const lightTheme = {
  body: '#f8f5f2',
  addItemBody: '#e8e3df',
  text: '#222525',
  buttonText: '#f5f3f4',
  shadowedText: '#3C3C3C',
  greyText: '#828282',
  title: '#232323',
  accentText: '#080299',
  accentTextHover: '#030060',
  accent: '#e91b0c',
  accentHover: '#C21919',
  boxShadow: 'rgba(220, 187, 160, 0.5)',
  smallBoxShadow: 'rgba(100, 100, 100, 0.3)',
  addItemBackground: 'rgba(255, 255, 254, 0.4)',
  background: '#fffffe',
  backgroundHover: '#f0ebe6',
  backgroundDeep: '#f0ebe6',
  backgroundDeepHover: '#e3deda',
  scrollBackground: '#f1f1f1',
  scrollThumb: '#c1c1c1',
  border: '#ede7e1',
  yellow: '#fff475',
  blue: '#cbf0f8',
  pink: '#ffc2d4',
  orange: '#fbbc04',
  green: '#ccff90',
  grey: '#e8eaed',
  default: '#f8f9fa',
  strengthBox: '#1f1f1f',
};
export const darkTheme = {
  body: '#202124',
  addItemBody: '#e8e3df',
  text: '#f5f3f4',
  buttonText: '#f5f3f4',
  shadowedText: '#f0f6f9',
  greyText: '#7a7a7a',
  warnText: '#9aa0a6',
  title: '#fffffe',
  accentText: '#ff8c83',
  accentTextHover: '#e91b0c',
  accent: '#e91b0c',
  accentHover: '#C21919',
  boxShadow: 'rgba(25, 25, 25, 0.8)',
  smallBoxShadow: 'rgba(15, 15, 15, 0.5)',
  addItemBackground: 'rgba(32, 33, 36, 0.6)',
  background: '#16171a',
  backgroundHover: '#2e3036',
  backgroundDeep: '#16171a',
  backgroundDeepHover: '#2e3036',
  scrollBackground: '#16171a',
  scrollThumb: '#2e3036',
  border: '#333333',
  yellow: '#635d19',
  blue: '#2d555e',
  pink: '#5b2245',
  orange: '#614a19',
  green: '#345920',
  grey: '#3c3f43',
  default: '#202124',
  strengthBox: '#b8aea5',
};
